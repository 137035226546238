<template>
	<div class="">
		<div v-if="loading">
			<div class="p-4 text-center center">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div v-else>
			<div class="row">
				<div class="col-12 col-xl-6">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Vehicle Information</h4>
							<div class="dropdown">
								<button class="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
									Actions
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<a v-if="userHasPermission('vehicles008')" class="dropdown-item" href="#" @click.prevent="showChangeDriver = true">Change Driver</a>

									<a v-if="userHasPermission('vehicles006')" class="dropdown-item" href="#" @click.prevent="showEditVehicleTracking = true">Update Tracking Details</a>

									<a v-if="userHasPermission('vehicles004')" class="dropdown-item" href="#" @click.prevent="$bvModal.show('editVehicleModal')">Edit Bus</a>
									<a class="dropdown-item" href="#" @click.prevent="showAllQrCode">export QR code</a>
									<a
										v-if="userHasPermission('vehicles005') && vehicle?.status === 'active'"
										class="dropdown-item text-danger"
										href="#"
										@click.prevent="decommissionVehicle"
										>De-commission Vehicle</a
										>
									<a
										v-else-if="userHasPermission('vehicles005') && vehicle?.status === 'inactive'"
										class="dropdown-item text-success"
										href="#"
										@click.prevent="commissionVehicle"
										>Commission Vehicle</a
										>


								</div>
							</div>
						</div>
						<div class="p-2 card-body">
							<div class="mb-4 list-group list-group-flush">
								<div class="flex items-center gap-4 list-group-item">
									<image-zoom style-class="mr-1 avatar-xxl">
										<template v-slot:image>
											<img :src="qrCodeImageUrl" alt="QR Code" class="border avatar avatar-xxl" />
										</template>
									</image-zoom>

									<span class='cursor-pointer' @click='showAllQrCode'>Export QR code</span>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Brand</span>
										</div>
										<div class="col-auto">
											<span class="">{{ vehicle?.brand ?? 'N/A' }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Name</span>
										</div>
										<div class="col-auto">
											{{ vehicle?.name }}
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Plate Number</span>
										</div>
										<div class="col-auto">
											<span class="">{{
												vehicle?.registration_number ?? 'N/A'
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Capacity</span>
										</div>
										<div class="col-auto">
											<span class="">{{ vehicle?.seats ?? 'N/A' }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Type</span>
										</div>
										<div class="col-auto">
											<span class="">{{ vehicle?.type ?? 'N/A' }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Amenities</span>
										</div>
										<div class="col-auto" v-if="vehicle?.amenity_list.length">
											<span class="" v-for="vehicleAmenity in vehicle?.amenity_list" :key="vehicleAmenity.id">{{ vehicleAmenity?.short_name + ', ' }}</span>
										</div>
										<div class="col-auto" v-else>
											<span class="text-muted">N/A</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Driver</span>
										</div>
										<div class="col-auto">
											<router-link v-if="vehicle.driver && vehicle.driver.id" :to="{
												name: 'ShowDriver',
												params: { driverId: vehicle.driver.id },
											}">
												<span>
													{{
														`${vehicle.driver.fname} ${vehicle.driver.lname}`
													}}
												</span>
											</router-link>
											<span v-else>Unassigned</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Commissioned Since</span>
										</div>
										<div class="col-auto">
											<span class="">{{
												vehicle?.created_at | date('hh:mm a MMMM d, yyyy')
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Partner</span>
										</div>
										<div class="col-auto" v-if="vehicle.partner">
											<router-link class="sh-page-link" :to="{
												name: 'PartnerDetails',
												params: {
													partnerId: vehicle?.partner?.id,
													accountSid: vehicle?.partner?.account_sid,
												},
											}">{{
	vehicle?.partner?.company_name ?? 'N/A'
}}</router-link>
										</div>
										<div class="col-auto" v-else>
											<span class="text-muted">No partner assigned</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-6">
					<vehicle-earnings numberOfTrips="0" :totalEarnings="vehicleEarning || 0"></vehicle-earnings>
				</div>
			</div>
		</div>
		<change-vehicle-driver-modal v-if="vehicle" :show="showChangeDriver" @close="showChangeDriver = false" :vehicle="vehicle" @driver-changed="processDriverChanged"></change-vehicle-driver-modal>

		<edit-vehicle-tracking-details-modal v-if="vehicle" :show="showEditVehicleTracking" @close="showEditVehicleTracking = false" :vehicle="vehicle"></edit-vehicle-tracking-details-modal>

		<b-modal id="editVehicleModal" hide-footer>
			<template #modal-title>
				<h4 class="card-header-title">Update Vehicle</h4>
			</template>
			<div class="card-body">
				<div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errorMessage">
					{{ errorMessage }}
				</div>
				<form @submit.prevent="updateVehicle">
					<div class="form-group row">
						<div class="col-12">
							<label class="form-label">Vehicle Brand</label>
							<input v-model.trim="updateVehicleModel.brand" required type="text" class="form-control" />
						</div>
					</div>
					<div class="form-group row">
						<div class="col-12">
							<label class="form-label">Vehicle Name</label>
							<input v-model.trim="updateVehicleModel.name" required type="text" class="form-control" />
						</div>
					</div>
					<div class="form-group row">
						<div class="col-12">
							<label class="form-label">Vehicle Plate Number</label>
							<input v-model.trim="updateVehicleModel.registration_number" required type="text" class="form-control" />
						</div>
					</div>
					<div class="form-group row">
						<div class="col-12 col-md-6">
							<label class="form-label">Vehicle Capacity</label>
							<input v-model.trim="updateVehicleModel.seats" required type="number" class="form-control" />
						</div>
						<div class="col-12 col-md-6">
							<label class="form-label">Vehicle Type</label>
							<select v-model="updateVehicleModel.type" class="form-control form-select">
								<option :value="null">Select</option>
								<option v-for="(option, index) in vehicleTypes" :value="option.name" :key="index">
									{{ option.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="form-group row">
						<div class="col-6">
							<label class="form-label">Vehicle Code</label>
							<input v-model.trim="updateVehicleModel.busCode" type="text" class="form-control" min="1" max="6" />
						</div>
						    <div class="col-6">
                    <label class="form-label">Vehicle inventory Type</label> 
                      <select v-model="updateVehicleModel.inventory_type" class="form-control form-select">
                      <option :value="null">Select</option>
                      <option v-for="(option, index) in ['regular', 'charter', 'one-off' ]" :value="option" :key="index">{{ option }}
                      </option>
                    </select>
                  </div>
					</div>
					<div class="form-group row" v-if="userHasPermission('vehicles004')">
						<div class="col-12">
							<label class="form-label">Vehicle Amenities</label>
							<b-form-checkbox-group v-model="updateVehicleModel.amenities" class="flex-wrap d-flex align-items-center">
								<b-form-checkbox class="mt-3" :value="amenity.id" v-for="amenity in amenitiesList" :key="amenity.id">{{ amenity.name }}</b-form-checkbox>
							</b-form-checkbox-group>
						</div>
					</div>
					<button type="submit" class="mt-4 btn btn-primary" :disabled="processing">
						{{ processing ? 'Processing...' : 'Update Vehicle' }}
					</button>
				</form>
			</div>
		</b-modal>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import Swal from 'sweetalert2'
import ChangeVehicleDriverModal from '@/components/modals/ChangeVehicleDriverModal.vue'
import EditVehicleTrackingDetailsModal from '@/components/modals/EditVehicleTrackingDetailsModal.vue'
import ImageZoom from '@/components/core/ImageZoom'
import VehicleEarnings from '@/views/Partners/components/VehicleEarnings.vue'

export default {
	props: {
		vehicleId: {
			required: true,
			type: [Number, String]
		}
		// accountSid: {
		//   required: true,
		//   type: [Number, String],
		// },
	},
	components: {
		ImageZoom,
		ChangeVehicleDriverModal,
		EditVehicleTrackingDetailsModal,
		VehicleEarnings
		// VehicleEditingModal,
	},
	data()
	{
		return {
			vehicle: null,
			loading: true,
			errorLoading: false,
			updateVehicleModel: {},
			processing: false,
			errorMessage: '',
			showChangeDriver: false,
			showVehicleEdit: false,
			showEditVehicleTracking: false,
			vehicleTypes: [],
			amenitiesList: [],
			partnerVehicleEarning: [],
		}
	},
	created()
	{
		this.init()
		this.fetchVehicleAmenities()
		this.fetchVehicleTypes()
		this.fetchPartnerVehicleEarning()
	},
	computed: {
		isVehicleActive()
		{
			if (!this.vehicle) {
				return false
			}

			return !!parseInt(this.vehicle.active)
		},
		qrCodeImageUrl()
		{
			return `${process.env.VUE_APP_API_URL}/v1/vehicles/${this.vehicle.id}/qrimage.png`
		},
		vehicleEarning()
		{
			return this.partnerVehicleEarning.reduce((accumulator, currentValue) =>
			{
				return accumulator + currentValue.finalPartnersRevenue
			}, 0)
		}
	},
	methods: {
		showAllQrCode()
		{
			const link = `${process.env.VUE_APP_API_URL}/v1/vehicles/${this.vehicle.id}/qrimage.png`
			window.open(link, '_blank')
			//   this.$swal({
			//     icon: 'question',
			//     title: 'Export vehicle QR code',
			//     text: 'Are you sure you want to export vehicle QR code?',
			//     showConfirmButton: true,
			//     showCancelButton: true,
			//     preConfirm: () => {

			//     },
			//     allowOutsideClick: () => !Swal.isLoading()
			//   })
		},
		async init()
		{
			this.loading = true
			this.errorLoading = false
			// this.fetchVehiclePartners()
			this.fetchActiveVehicle()
				.then(() => { })
				.catch(() => (this.errorLoading = true))
				.finally(() => (this.loading = false))
		},
		fetchVehicleTypes()
		{
			this.axios.get('/v1/vehicle-types').then((res) =>
			{
				this.vehicleTypes = res.data.data
			})
		},
		fetchActiveVehicle()
		{
			return this.axios.get(`/v1/vehicles/${this.vehicleId}`).then((res) =>
			{
				this.vehicle = res.data
				const amenities = (this.vehicle?.amenity_list || []).map(
					(amenity) => amenity.id
				)
				this.updateVehicleModel = { ...this.vehicle, amenities }
			})
		},
		async decommissionVehicle()
		{
			await this.$swal({
				icon: 'question',
				title: 'Please Confirm',
				text: 'Are you sure you want to delete this vehicle?',
				showConfirmButton: true,
				showCancelButton: true,
				preConfirm: () =>
				{
					return this.axios
						.delete(`/v1/vehicles/${this.vehicleId}`)
						.catch((error) =>
						{
							const msg =
								error.response && error.response.data
									? error.response.data.message
									: 'An error occured, please try again.'

							this.$swal().showValidationMessage(msg)
						})
				},
				allowOutsideClick: () => !Swal.isLoading()
			}).then((result) =>
			{
				if (result.isConfirmed) {
					this.$swal({
						icon: 'success',
						title: 'Success',
						text: 'Vehicle has been de-commissioned successfully',
						showCloseButton: true
					})

					this.init()
				}
			})
		},
		async commissionVehicle(){
			await this.$swal({
				icon: 'question',
				title: 'Please Confirm',
				text: 'Are you sure you want to commission this vehicle?',
				showConfirmButton: true,
				showCancelButton: true,
				preConfirm: () =>
				{
					return this.axios
						.patch(`/v1/vehicles/${this.vehicleId}`, {
							status: 'active',
						})
						.catch((error) =>
						{
							const msg =
								error.response && error.response.data
									? error.response.data.message
									: 'An error occured, please try again.'

							this.$swal().showValidationMessage(msg)
						})
				},
				allowOutsideClick: () => !Swal.isLoading()
			}).then((result) =>
			{
				if (result.isConfirmed) {
					this.$swal({
						icon: 'success',
						title: 'Success',
						text: 'Vehicle has been Commissioned successfully',
						showCloseButton: true
					})

					this.init()
				}
			})
		},
		updateVehicle()
		{
			this.processing = true

			this.errorMessage = ''
			this.axios
				.patch(`/v1/vehicles/${this.vehicle.id}`, {
					brand: this.updateVehicleModel?.brand,
					name: this.updateVehicleModel?.name,
					type: this.updateVehicleModel?.type,
					seats: this.updateVehicleModel?.seats,
					registration_number: this.updateVehicleModel?.registration_number,
					amenity_ids: this.updateVehicleModel?.amenities,
					inventory_type: this.updateVehicleModel?.inventory_type,
					code: this.updateVehicleModel?.busCode
						? this.updateVehicleModel?.busCode
						: null
				})
				.then(() =>
				{
					this.fetchVehicleTypes()
					// this.fetchVehiclePartners()
					this.fetchActiveVehicle()
					this.vehicle = { ...this.updateVehicleModel }
					this.$bvModal.hide('editVehicleModal')
					this.$swal({
						icon: 'success',
						title: 'Vehicle Updated',
						text: 'Vehicle information has been updated successfully',
						showCloseButton: true
					})
				})
				.catch((e) =>
				{
					this.$swal({
						icon: 'error',
						title: 'Error Occured',
						text: 'An error occured, please review',
						showCloseButton: true
					})

					let msg = e.toString()
					if (e && e.response && e.response.data && e.response.data.message) {
						msg = e.response.data.message
					}

					this.errorMessage = msg
				})
				.finally(() => (this.processing = false))
		},
		processDriverChanged(driver)
		{
			this.vehicle.driver = driver
		},
		async fetchPartnerVehicleEarning()
		{
			await this.axios
				.get(
					`/cost-revenue/v1/revenues?vehicleId=${this.vehicleId}&page=1&perPage=10`
				)
				.then((res) =>
				{
					this.partnerVehicleEarning = res.data.result
				})
		},
		async fetchVehicleAmenities()
		{
			const amenities = await this.axios.get(
				'/v1/vehicle-amenities?metadata=true'
			)
			this.amenitiesList = amenities.data?.data ?? []
		}
	}
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/partials/overrides/v-select';
</style>
