<template>
	<div class="card">
		<div class="card-header d-flex justify-content-between align-items-center">
			<h3 class="card-header-title text-sm">Vehicle Earnings</h3>
			<!-- <div class="w-1/2">
	<v-datepicker
				v-model="range"
				placeholder="Filter by date"
				range
				style="width: 50%"
				></v-datepicker>
			</div> -->
		
		</div>
		<div class="card-body">
			<template>
				<div class="row g-0 align-items-center justify-content-center">
					<div class="col-auto">
						<h1 class="display-3 mb-0">{{ vehiclesEarnings }}</h1>
					</div>
				</div>
				<div class="h5 mt-1 text-uppercase text-center text-muted mb-5">
					Vehicle Earnings
				</div>
				<div class="border-top border-muted">
					<div class="row py-3">
						<div class="col text-muted">Total no. of trips </div>
						<div class="col-auto">
							{{ numberOfTrips ? numberOfTrips : 'N/A' }}
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
  name: 'VehicleEarnings',
  props: {
    numberOfTrips: {
      type: [Number, String],
      default: null
    },
    totalEarnings: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      range: []
    }
  },
  computed: {
    vehiclesEarnings () {
      return !this.totalEarnings
        ? '₦0.00'
        : `₦${parseFloat(this.totalEarnings)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
    }
  }
}
</script>

<style lang="scss" scoped>
.pat-link {
  color: #4848ed;
  text-decoration: underline;
}
</style>
