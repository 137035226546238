<template>
	<div
		class="modal fade"
		id="editVehicleTrackingDetailsModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="changeVehicleDriverLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="vehicle"
			>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="changeVehicleDriverLabel">
						Update Vehicle Tracking Details
					</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="submit">
						<div class="row" v-if="showAutoComplete">
							<div class="col-10">
								<div class="form-group">
									<label class="form-label" for="selectedProvider">Pick Tracking Provider</label>
									<v-select
										@input="onProviderSelected($event)"
										v-model="selectedProvider"
										class="form-group"
										:options="trackerProviders"
										label="name"
										placeholder="Select a provider"
										required
										>
										<template v-slot:option="selectedProvider">
											{{ selectedProvider.name }}
										</template>
										<template v-slot:selected-option="selectedProvider">
											{{ selectedProvider.name }}
										</template>
									</v-select>
								</div>
							</div>
							<div class="col-2">

								<button
									type="button"
									class="btn btn-primary btn-sm add-button"
									@click="showAutoComplete = false"
									>
									<i class="fa fa-plus"></i>
								</button>

							</div>
						</div>
						<div class="row" v-if="!showAutoComplete">
							<div class="col-12">
								<div class="form-group">
									<label class="form-label">Tracking ID</label>
									<input
										v-model.trim="form.tracking_id"
										required
										type="text"
										class="form-control"
										/>
								</div>
								<div class="form-group">
									<label class="form-label">Provider Name</label>
									<input
										v-model.trim="form.provider_name"
										required
										type="text"
										class="form-control"
										/>
								</div>
								<div class="form-group">
									<label class="form-label">Provider Description</label>
									<textarea
										v-model.trim="form.provider_description"
										required
										type="text"
										class="form-control"
										></textarea>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="submit"
						:disabled="processing || !formValid"
						>
						{{ processing ? 'Processing...' : 'Save' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#editVehicleTrackingDetailsModal"
			ref="modalToggle"
			></button>
	</div>
</template>
<style scoped>
.add-button {
  margin-top: 31px;
  margin-left: -5px;
}
</style>
<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    vehicle: Object
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        tracking_id: null,
        provider_name: null,
        provider_description: null
      },
      processing: false,
      errorMessage: '',
      showAutoComplete: false,
      selectedProvider: null
    }
  },
  created () {
    if (this.vehicle.trackerProvider) {
      this.form.tracking_id = this.vehicle.tracking_id
      this.form.provider_name = this.vehicle.trackerProvider.name
      this.form.provider_description = this.vehicle.trackerProvider.description
      this.showAutoComplete = false
    }
  },
  computed: {
    anyFormFieldFilled () {
      return this.form.tracking_id || this.form.provider_name || this.form.provider_description
    },
    formValid () {
      return this.form.tracking_id && this.form.provider_name && this.form.provider_description
    },
    trackerProviders () {
      return []
    }
  },
  methods: {
    onProviderSelected (provider) {
      const selectedVehicle = this.vehicles.find((vehicle) => (vehicle.tracker_provider_id === provider.id))
      this.form.tracking_id = selectedVehicle.tracking_id
      this.form.provider_name = provider.name
      this.form.provider_description = provider.description
      this.showAutoComplete = false
    },
    async submit () {
      if (!this.formValid) {
        return
      }

      this.processing = true

      this.errorMessage = ''

      this.updateVehicleTracking(this.vehicle.id, {
        tracking_id: this.form.tracking_id,
        provider_name: this.form.provider_name,
        provider_description: this.form.provider_description
      }).then(() => {
        this.close()

        this.$swal({
          icon: 'success',
          title: 'Tracking Updated',
          text: 'Tracking details has been updated successfully',
          showCloseButton: true
        })
      })
        .catch((e) => {
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    },
    updateVehicleTracking (vehicleId, data) {
      return this.axios.put(`/v1/vehicles/${vehicleId}/tracking`, {
        ...data
      })
    }
  }
}
</script>
