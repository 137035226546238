<template>
	<div
		class="modal fade"
		id="changeVehicleDriverModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="changeVehicleDriverLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="vehicle"
			>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="changeVehicleDriverLabel">
						Change driver
					</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="changeVehicleDriver">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label class="form-label" for="driver">Select Driver</label>
									<v-select
										v-model="form.driver"
										class="form-group"
										:options="drivers"
										label="fname"
										placeholder="Select a driver"
										required
										>
										<template v-slot:option="driver">
											{{ `${driver.fname} ${driver.lname}` }}
										</template>
										<template v-slot:selected-option="driver">
											{{ `${driver.fname} ${driver.lname}` }}
										</template>
									</v-select>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="changeVehicleDriver"
						:disabled="processing || !formValid"
						>
						{{ processing ? 'Processing...' : 'Update Driver' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#changeVehicleDriverModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    vehicle: Object
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        driver: null
      },
      processing: false,
      errorMessage: '',
      drivers: []
    }
  },
  created () {
    this.fetchDrivers()

    if (this.vehicle.driver && this.vehicle.driver.id) {
      this.form.driver = { ...this.vehicle.driver }
    }
  },
  computed: {
    formValid () {
      return this.form.driver
    }
  },
  methods: {
    fetchDrivers () {
      this.axios.get('/v1/drivers').then((res) => {
        this.drivers = res.data
      })
    },
    async changeVehicleDriver () {
      if (!this.formValid) {
        return
      }

      this.processing = true

      this.errorMessage = ''

      if (
        this.vehicle.driver &&
        Object.prototype.hasOwnProperty.call(this.vehicle.driver, 'id')
      ) {
        await this.updateDriver(this.vehicle.driver.id, {
          vehicle_id: null
        })
      }

      this.updateDriver(this.form.driver.id, {
        vehicle_id: this.vehicle.id
      })
        .then(() => {
          this.close()

          this.$swal({
            icon: 'success',
            title: 'Driver Updated',
            text: 'Driver has been updated successfully',
            showCloseButton: true
          })

          this.$emit('driver-changed', this.form.driver)
        })
        .catch((e) => {
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    },
    updateDriver (driverId, data) {
      return this.axios.patch(`/v1/drivers/${driverId}`, {
        ...data
      })
    }
  }
}
</script>
